import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import UnderlineColorTextField from '../inputs/UnderlineTextField';
import PrimaryFormCard from '../cards/PrimaryFormCard';

const KeyCard = ({ onConfirm, loading, errorMessage }) => {
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  return (
    <PrimaryFormCard
      leftSideComponent={(
        <Stack
          spacing={2}
          alignItems="center"
          width="100%"
          height="auto"
          justifyContent="center"
        >
          <MailOutlinedIcon sx={{ fontSize: 50, color: '#5A5A5A' }} />
          <Typography variant="body1" align="center">
            Ingresa la clave enviada a tu mail para declarar la firma del documento de operación.
          </Typography>
        </Stack>
      )}
      rightSideComponent={(
        <Stack spacing={2} justifyContent="center" alignItems="center" width="100%" height="100%">
          <UnderlineColorTextField
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
            variant="standard"
            fullWidth
            InputProps={{
              sx: { color: 'common.white' },
            }}
            underlineColor="common.white"
            placeholder="Ingresa la clave"
          />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checked} color="common.white" sx={{ color: 'common.white' }} />}
              label={(
                <Typography variant="body1" color="common.white">
                  Al ingresar declaras haber leído y aceptado los
                  <Link target="_blank" to="/app/profile/terms" component={RouterLink} color="inherit" fontWeight={700} underline="hover"> “Términos y condiciones”</Link>
                </Typography>
                 )}
              onChange={() => setChecked((prev) => !prev)}
            />
          </FormGroup>
          <LoadingButton
            variant="containedReverse"
            color="primary"
            size="small"
            fullWidth
            disabled={password.length === 0 || !checked}
            sx={{ maxWidth: 197 }}
            loading={loading}
            onClick={() => onConfirm(password)}
          >
            Confirmar
          </LoadingButton>
          <Typography color="common.white">{errorMessage}</Typography>
        </Stack>
      )}
    />
  );
};

KeyCard.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

KeyCard.defaultProps = {
  errorMessage: '',
};

export default KeyCard;
